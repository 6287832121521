import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["packageOption"];

  assignPackage(event) {

    //disable the button to prevent double submission
    event.currentTarget.disabled = true;

    let selectedPackageElement = this.packageOptionTargets.find(
      (element) => element.querySelector('input[type="radio"]').checked
    );
    let packageId = selectedPackageElement.dataset.packageId;
    let schoolsNavigationToken = document.querySelector(
      "#school_navigation_token"
    ).value;

    let request_url = `/schools/pupils/${schoolsNavigationToken}/actions/assign-package/${packageId}`;
    let debug = new URLSearchParams(window.location.search).get("debug");
    if (debug) {
      request_url += "?debug=" + debug;
    }

    let success_url = `/schools/pupils/${schoolsNavigationToken}/actions?package_assigned=true`;

    fetch(request_url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        Accept: "text/html",
      },
      credentials: "same-origin",
    })
      .then((response) => response.text())
      .then((html) => {
        window.location.href = success_url;
      })
      .catch((response) => {
        console.log(response);
        window.location.href = request_url + "?error=true";
      });
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
