import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async updateNotes(event) {
    console.log("updateNotes");

    const notesContent = document.querySelector(
      'textarea[name="notes_content"]'
    ).value;
    const schoolToken = this.data.get("schoolToken");
    const lessonId = event.currentTarget.dataset.lessonId; // get the lesson ID from the data attribute of the button
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    let button = event.currentTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.remove("u-hidden");
    spinner.classList.add("u-inlineBlock");
    button.disabled = true;

    const url = `/schools/${schoolToken}/lessons/${lessonId}/update-notes`;
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        notes: notesContent,
      }),
    });

    if (response.ok) {
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      button.disabled = false;

      const stream = await response.text();

      // find lesson-notes-save-success and show it for 2 seconds, slide it in.
      const successMessage = document.getElementById(
        "lesson-notes-save-success"
      );
      successMessage.hidden = false;
      setTimeout(() => {
        successMessage.hidden = true;
      }, 2000);

      return true;
    } else {
      let spinner = button.querySelector(".Spinner");
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      button.disabled = false;

      const responseBody = await response.text();
      const asJson = JSON.parse(responseBody);
      const errorMessage =
        asJson["error"] ||
        "Something went wrong, please check the details and try again";
      alert(errorMessage);
      return false;
    }
  }
}
